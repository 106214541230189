$(window).scroll(function(){
    var sticky = $('.header__top');
    var sticky2 = $('.filter__mob');
    scroll = $(window).scrollTop();

    if (scroll >= 50) sticky.addClass('fixheader');
    else sticky.removeClass('fixheader');
    if (scroll >= 250) sticky2.addClass('fixfilter');
    else sticky2.removeClass('fixfilter');
});

$(function() {
    var $slider = $("#slider-range");
    //Get min and max values
    var priceMin = $slider.attr("data-price-min"),
        priceMax = $slider.attr("data-price-max"),
        priceMinSelect = $slider.attr('data-select-min'),
        priceMaxSelect = $slider.attr('data-select-max');

    var timer;

    function applyCatalogFilterPrice() {
        clearTimeout(timer);
        timer = setTimeout(function () {
            $('[data-price-slider-form]').submit();
        }, 1000);
    }

    if (!priceMinSelect) priceMinSelect = priceMin;
    if (!priceMaxSelect) priceMaxSelect = priceMax;

    //Set min and max values where relevant
    $("#price-filter-min, #price-filter-max").map(function(){
        $(this).attr({
            "min": priceMinSelect,
            "max": priceMaxSelect
        });
    });
    $("#price-filter-min").attr({
        "placeholder": "min " + priceMinSelect,
        "value": priceMinSelect
    });
    $("#price-filter-max").attr({
        "placeholder": "max " + priceMaxSelect,
        "value": priceMaxSelect
    });

    $slider.slider({
        range: true,
        min: Math.max(priceMin, 0),
        max: priceMax,
        values: [priceMinSelect, priceMaxSelect],
        slide: function(event, ui) {
            $("#price-filter-min").val(ui.values[0]);
            $("#price-filter-max").val(ui.values[1]);
        }
    });

    $("#price-filter-min, #price-filter-max").map(function(){
        $(this).on("input", function() {
            updateSlider();
        });
    });
    function updateSlider(){
        $slider.slider("values", [$("#price-filter-min").val(), $("#price-filter-max").val()]);
    }


    //Only once on load, add classes to checklists
    $( ".checklist" ).map(function(){
        let $list = $(this);
        if($list.children().length > 3){
            $list.addClass('collapsed');
        }
        //function to remove class (once) when more is clicked
        function handleMore(e){
            if($(e.target).is('ul')){
                $(this).removeClass('collapsed');
                $(this).addClass('revealed');

                //make it two columns if items are not long and there's many
                if($(this).hasClass("short") && $(this).children().length >= 5){
                    $(this).addClass('columns');
                }
                //remove event handler
                $(this).off('click.moreButton');
            }
        }
        //and attached event handler to ul
        $list.on('click.moreButton', handleMore);
    });
});


$(function() {

    var $nav = $('nav.greedy');
    var $btn = $('nav.greedy button');
    var $vlinks = $('nav.greedy .links');
    var $hlinks = $('nav.greedy .hidden-links');

    var numOfItems = 0;
    var totalSpace = 0;
    var breakWidths = [];

    // Get initial state
    $vlinks.children().outerWidth(function(i, w) {
        totalSpace += w;
        numOfItems += 1;
        breakWidths.push(totalSpace);
    });

    var availableSpace, numOfVisibleItems, requiredSpace;

    function check() {

        // Get instant state
        availableSpace = $vlinks.width() - 10;
        numOfVisibleItems = $vlinks.children().length;
        requiredSpace = breakWidths[numOfVisibleItems - 1];

        // There is not enought space
        if (requiredSpace > availableSpace) {
            $vlinks.children().last().prependTo($hlinks);
            numOfVisibleItems -= 1;
            check();
            // There is more than enough space
        } else if (availableSpace > breakWidths[numOfVisibleItems]) {
            $hlinks.children().first().appendTo($vlinks);
            numOfVisibleItems += 1;
        }
        // Update the button accordingly
        $btn.attr("count", numOfItems - numOfVisibleItems);
        if (numOfVisibleItems === numOfItems) {
            $btn.addClass('hidden');
        } else $btn.removeClass('hidden');
    }

    // Window listeners
    $(window).resize(function() {
        check();
    });

    $btn.on('click', function() {
        $hlinks.toggleClass('hidden');
    });

    check();

});

$(window).ready(function () {
    $('.tab_content').hide();
    $('.tab_content:first').show();
    $('.tabs__product li:first').addClass('active');
    $('.tabs__product li').click(function(event) {
        $('.tabs__product li').removeClass('active');
        $(this).addClass('active');
        $('.tab_content').hide();

        var selectTab = $(this).find('a').attr("href");

        $(selectTab).fadeIn();
    });


    $('.tabs__cart li:first').addClass('active');
    $('.tabs__cart li').click(function(event) {
        $('.tabs__cart li').removeClass('active');
        $(this).addClass('active');
        $('.tab_content').hide();

        var selectTab = $(this).find('a').attr("href");

        $(selectTab).fadeIn();
    });


    $('#tab2 .form__block_dc input').on("keyup",function () {
        var value = $('#tab2 .form__item input').filter(function () {
            return this.value === '';
        });
        if (value.length == 0) { $('.cart__page__3').removeClass('nexthide');
        } else if (value.length > 0) {
            $('.cart__page__3').addClass('nexthide');
        }
    });


    $(".cart__page__1").click(function(e) {
        $('.tabs__cart__top li:nth-child(1)').removeClass('active').addClass('good');
        $('.tabs__cart__top li:nth-child(2)').removeClass('good').addClass('active');
    });

    $(".cart__page__3").click(function(e) {
        $('.tabs__cart__top li:nth-child(2)').removeClass('active').addClass('good');
        $('.tabs__cart__top li:nth-child(3)').addClass('active');
    });

    $(".cart__page__2").click(function(e) {
        $('.tabs__cart__top li:nth-child(1)').addClass('active').removeClass('good');
        $('.tabs__cart__top li:nth-child(2)').removeClass('active');
        $('.tabs__cart__top li:nth-child(3)').removeClass('active');
    });

    $(".tabs__product li a").click(function(e) {
        e.preventDefault();
    });
    $(".tabs__cart li a").click(function(e) {
        e.preventDefault();
    });
    $('.form__item input').each(function(){
        if($(this).val().trim()){
            $(this).prev('label').addClass('moveUp');
        }
    });
    $('.form__item textarea').each(function(){
        if($(this).val().trim()){
            $(this).prev('label').addClass('moveUp');
        }
    });

    $('.form__item input').on('focus', function() {
        $(this).prev('label').addClass('moveUp');
    });

    $('.form__item input').on('focusout', function() {
        if(!$(this).val()) $(this).prev('label').removeClass('moveUp');
    });
    $('.form__item textarea').on('focus', function() {
        $(this).prev('label').addClass('moveUp');
    });

    $('.form__item textarea').on('focusout', function() {
        if(!$(this).val()) $(this).prev('label').removeClass('moveUp');
    });

    $('.product__add__cart .minus').click(function () {
        var $input = $(this).parent().find('input');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;

    });
    $('.product__add__cart .plus').click(function () {
        var $input = $(this).parent().find('input');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        return false;
    });

    if($(window).width() >= 991){
        $(".category__menu").hover(function(){
            $('button.burger').toggleClass("active");
            $('.wsshoptabing').toggleClass("hoverclass");
        });
    } else {
        $(".category__menu").click(function(){
            $('button.burger').toggleClass("active");
            $('.wsshoptabing').toggleClass("hoverclass");
        });
    }
    $(".header__top__right .search__btn button.head__sr_btn").click(function(){
        $('.search__panel').toggleClass("active");
    });
    $(".header__top__left .search__btn button.head__sr_btn").click(function(){
        $('.search__panel').toggleClass("active");
    });
    $(".filter__mob, .close__filter").click(function(){
        $('.catalog__sidebar').toggleClass("active");
        $('.sidebar__head').toggleClass("active");
        $('body').toggleClass("over__hide");
    });
    $(".link__menu__btn").click(function(){
        $('.link__menu').toggleClass("active");
        $('body').toggleClass("over__hide");
    });
    $(".menu__tt a").click(function(){
        $('.link__menu').toggleClass("active");
        $('body').toggleClass("over__hide");
    });
    $(".only__distinction").click(function(){
        $(this).toggleClass("active");
        $('.only__d').toggleClass("hide__distinction");
    });
    $(".grid__btn").click(function(){
        $(this).addClass("active");
        $('.catalog__product__item').addClass("grid").removeClass("list");
        $('.list__btn').removeClass("active");
    });
    $(".list__btn").click(function(){
        $(this).addClass("active");
        $('.catalog__product__item').removeClass("grid").addClass("list");
        $('.grid__btn').removeClass("active");
    });
    $(".close__link__menu").click(function(){
        $('.link__menu').removeClass("active");
        $('body').removeClass("over__hide");
    });
    $(".close__search").click(function(){
        $('.search__panel').toggleClass("active");
    });
    $(".wsmenu .close__megamenu").click(function(){
        $('body').removeClass("wsactive");
    });



    $('.compare__slider').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: true,
        dots: false,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 880,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.next-compare').click(function(){
        $('.compare__slider').slick("slickNext");
    });

    $('.prev-compare').click(function(){
        $('.compare__slider').slick("slickPrev");
    });

    $('.header__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        fade: true,
        responsive: [
            {
                breakpoint: 690,
                settings: {
                    dots: true,
                    arrows: false
                }
            }
        ]
    });
    $('.header__slider__2').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        fade: true,
        asNavFor: '.slider-nav',
        responsive: [
            {
                breakpoint: 690,
                settings: {
                    dots: true,
                    arrows: false
                }
            }
        ]
    });
    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.header__slider__2',
        dots: false,
        arrows: false,
        focusOnSelect: true
    });

    $('.carousel__home__block').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        swipeToSlide: true,
        dots: false,
        fade: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });

    $('.brends__home__block').slick({
        slidesToShow: 5,
        arrows: false,
        dots: false,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 2000,
        fade: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });


    $('.product__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        vertical: false,
        swipeToSlide: true,
        asNavFor: '.product__slider__nav',
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    verticalSwiping: false,
                    vertical: false
                }
            }
        ]
    });
    $('.product__slider__nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.product__slider',
        dots: false,
        arrows: false,
        vertical: true,
        focusOnSelect: true
    });

    $('.product__slider').slickLightbox({
        itemSelector: '.slick-slide > a'
    });
});